import { useEffect } from 'react'
import tw, { styled } from 'twin.macro'
import parse from 'html-react-parser'
import { Link } from 'gatsby'

import FlexSection from '@components/FlexSection'
import FlexIcon from '@components/FlexIcon'

import { componentLoaded, findColors } from '@utils/functions'

import Arrow from '@svg/arrow.svg'

import {
  WpPage_Acfpagefields_Content_BoxWithIconAndCtaVertical3Lines,
  WpPrograma_Acfpagefields_Content_BoxWithIconAndCtaVertical3Lines
} from '@_types/graphql-types'

type contentType =
  | WpPage_Acfpagefields_Content_BoxWithIconAndCtaVertical3Lines
  | WpPrograma_Acfpagefields_Content_BoxWithIconAndCtaVertical3Lines

const BoxWithIconAndCtaVertical3Lines = ({
  data,
  index,
  tabs
}: {
  data: contentType
  index: string
  tabs?: boolean
}) => {
  const { text, title, bgColor, boxColor, columns, tagTitle } = data

  const colors = findColors(bgColor)
  const boxColors = findColors(boxColor)

  useEffect(() => {
    componentLoaded(title)
  }, [])

  return (
    <FlexSection
      title={title}
      tagTitle={tagTitle}
      colors={colors}
      alignTitle="left"
      index={index}
      paddingTop
      id={title || ''}
      paddingBottom
      tabs={tabs}
      decorator={[
        {
          color: 'white',
          width: 430,
          x: 5,
          y: 0
        }
      ]}
    >
      <div tw="flex flex-wrap justify-between">
        <div tw="lg:max-w-md xl:max-w-xl 2xl:max-w-2xl w-full overflow-auto h-full mb-4 lg:mb-0">
          <div
            tw="lg:ml-4 prose"
            css={[colors.text === 'light' && tw`text-white!`]}
          >
            {text && parse(text)}
          </div>
        </div>
        <div tw="lg:max-w-md xl:max-w-xl 2xl:max-w-2xl w-full overflow-auto h-full mb-4 lg:mb-0">
          {columns &&
            columns.map((column, index) => {
              const iconColors = findColors(column.svgIcon.iconColor)
              return (
                <BoxContainer
                  css={[boxColors && boxColors.bg]}
                  to={column.cta.url}
                  key={index}
                >
                  <div tw="flex items-center">
                    <FlexIcon
                      name={column.svgIcon.iconType}
                      css={[iconColors.svg]}
                      tw="w-16 mx-auto mr-4"
                    />
                    <span tw="font-medium flex-1">{column.cta.title}</span>
                  </div>
                  <Arrow
                    className="transform -translate-y-1/2"
                    tw="text-lemann-red w-8 absolute top-1/2 right-8"
                  />
                </BoxContainer>
              )
            })}
        </div>
      </div>
    </FlexSection>
  )
}

const BoxContainer = styled(Link)`
  ${tw`relative block max-w-md mb-6 border border-gray-100 shadow-lg rounded-4xl py-6 px-8`}
`

export default BoxWithIconAndCtaVertical3Lines
