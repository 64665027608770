import loadable from '@loadable/component'
import { isServer } from '@utils/functions'

interface IProps extends React.SVGAttributes<SVGElement> {
  name: string
}

const FlexIcon: React.FC<IProps> = ({ name, ...rest }) => {
  if (isServer) return null

  const svgIcons = {
    bullseye: 'bullseye',
    eye: 'eye',
    plant: 'plant',
    'play-button': 'play-button',
    student: 'student',
    brain: 'brain',
    book: 'book',
    rocket: 'rocket',
    people: 'people',
    graph: 'graph',
    heart: 'heart',
    partnership: 'partnership'
  }

  const Icon = loadable(() => import(`../../assets/svg/${svgIcons[name]}.svg`))

  return <Icon {...rest} />
}

export default FlexIcon
